import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    isAdmin
    featureIds
  }
`;

export const USER_WITH_AUTH0_FRAGMENT = gql`
  fragment UserWithAuth0Fragment on User {
    id
    email
    name
    isAdmin
    featureIds
    auth0User {
      userId
      name
      email
      emailVerified
      hasUsernamePasswordConnection
      username
      picture
      appMetadata
    }
  }
`;
