import * as React from 'react';
import cx from 'classnames';
import { sortBy } from 'lodash';

import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Table,
  Button, Typography, Alert,
} from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Title, Text } = Typography;
import { LoadSpinner } from '@frontend/lib';
import getColumnSearchProps from '@frontend/app/utils/getColumnSearchProps/getColumnSearchProps';
import { EditUserDrawer } from './EditUserDrawer';

import {
  IUser,
  useGetUsers,
  useSaveUser,
} from '@frontend/app/hooks';

const { useState, useMemo } = React;
import styles from './UserListView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const UserListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { users, loading, refetch, error } = useGetUsers();
  const { saveUser, loading: creating } = useSaveUser();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const sortedUsers = useMemo(() => sortBy(users, 'name'), [users]);
  const goToDetailView = (userId: string) => {
    history.push(`${match.url}/${userId}`);
  };

  return (
    <div className={cx(styles.UserListView, props.className)}>
      <div className={styles.header}>
        <Title>Users</Title>
        <div className={styles.actions}>
          <Button
            onClick={() => refetch()}
            shape="circle"
            icon={<RedoOutlined />}
            loading={loading}
          />
          <Button
            className={styles.createButton}
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setOpenCreateUserModal(true)}
            disabled={loading}
          >
            Create User
          </Button>
        </div>
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && !error &&
        <>
          <Table
            rowKey={(user: IUser) => user.id}
            dataSource={sortedUsers}
            className={styles.table}
          >
            <Column
              title="Name"
              key="name"
              render={(_, user: IUser) => (
                <span
                  className={styles.nameCell}
                  onClick={goToDetailView.bind(this, user.id)}
                >
                  <Text ellipsis className={styles.name}>{user.name}</Text>
                </span>
              )}
              {...getColumnSearchProps("name")}
            />
            <Column title="Email" dataIndex="email" {...getColumnSearchProps("email")}/>
          </Table>
          <EditUserDrawer
            open={openCreateUserModal}
            onRequstClose={() => setOpenCreateUserModal(false)}
            isSubmitting={creating}
            onSubmit={(user) => {
              saveUser({
                variables: {
                  user,
                },
              }).then(() => setOpenCreateUserModal(false))
            }}
          />
        </>
      }
      {
        !loading && error && (
          <Alert
            type="error"
            message={error.message}
            description="This is most likely because your local db stores a record that has already been deleted on auth0. To fix it, try delete the corrupted record from postgres."
          />
        )
      }
    </div>
  );
});

export default UserListView;
