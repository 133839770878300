/* eslint-disable */
import * as React from 'react';
import cx from 'classnames';
import {
  split, last, isNil, pick, trim,
} from 'lodash';

import {
  Link, Route, Redirect, Switch,
  useHistory, useParams, useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Spin, Button, Typography,
  Menu, Dropdown, Tag, Tooltip,
} from 'antd';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;
import { EditClientDrawer } from './EditClientDrawer';

import {
  useDeleteClientById,
  useGetClientById,
  useSaveClient,
  useUserQALogin,
  useGetClientElevateCampaigns,
  useUpdateProgramCampaign,
  useExportBriefs,
} from '@frontend/app/hooks';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';
import { useClientCompletedOnboarding } from '@frontend/app/hooks/clients/useClientCompletedOnboarding';

const { lazy, useState, useCallback } = React;
import styles from './ClientDetailView.scss';

const ClientDetails = lazy(() => import('./ClientDetailView/ClientDetails'));
const ClientUsers = lazy(() => import('./ClientDetailView/ClientUsers'));
const ClientApplications = lazy(() => import('./ClientDetailView/ClientApplications'));
const ClientPrograms = lazy(() => import('./ClientDetailView/ClientPrograms'));
const ClientMembers = lazy(() => import('./ClientDetailView/ClientMembers'));
const ClientMemberTags = lazy(() => import('./ClientDetailView/ClientMemberTags'));
const ClientIntegrations = lazy(() => import('./ClientDetailView/ClientIntegrations'));
const ClientConnectedEmails = lazy(() => import('./ClientDetailView/ClientConnectedEmails/ClientConnectedEmails'));
const ClientIGDM = lazy(() => import('./ClientDetailView/ClientConnectedIGDM/ClientConnectedIGDM'));
const ClientOffers = lazy(() => import('./ClientDetailView/ClientOffers'));
const ClientEmbeddedReporting = lazy(() => import('./ClientDetailView/ClientEmbeddedReporting'));
const ApplicantReview = lazy(() => import('./ClientDetailView/ApplicantReview'));
const AdditionalDomains = lazy(() => import('./ClientDetailView/AdditionalDomains'));

interface IMatchParams {
  clientId: string;
}
interface IProps {
  className?: string;
}

// number of concurrent requests from front end to update client campaigns
const CONCURRENT_CAMPAIGN_UPDATE_BATCH = 3;

/**
 * @type {React.FunctionComponent}
 */
const ClientDetailView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { clientId } = useParams<IMatchParams>();
  const { client, loading } = useGetClientById({
    variables: {
      clientId,
    },
  });
  const { saveClient, loading: saving } = useSaveClient();
  const [exportBriefs, { data: { url: downloadBriefsUrl } = { url: null }, loading: exportingBriefs }] = useExportBriefs();
  const { clientCompletedOnboarding, loading: completedOnboardingLoading } = useClientCompletedOnboarding();
  const { deleteClientById } = useDeleteClientById();
  const { userQALogin, loading: loggingIn } = useUserQALogin({
    onCompleted: (data) => {
      window.open(data.redirectUrl, '_blank');
    },
  });
  const lastRoute = last(split(location.pathname, '/'));
  const [selectedRoute, setSelectedRoute] = useState(
    [
      'details', 'users', 'apps',
      'programs', 'members', 'member_tags',
      'integrations',
    ].includes(lastRoute) ? lastRoute : 'details',
  );
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const { updateProgramCampaign } = useUpdateProgramCampaign();

  const { campaigns = [] } = useGetClientElevateCampaigns({
    variables: {
      clientId: client?.id
    },
    skip: !client?.id,
  });

  const handleSubmit = useCallback(async (params) => {
    if (!client?.id) return;

    const oldHostname = client.hostname;
    const newHostname = trim(params.hostname);
    const hostnameChanged = newHostname !== oldHostname;

    await saveClient({
      variables: {
        client: {
          ...params,
          id: client.id,
        },
      },
    });

    if (hostnameChanged) {
      await Promise.limitedAll(
        campaigns,
        async (campaign) => {
          let listingUrl = campaign?.external_listing_url;
          if (!listingUrl) return;

          const parsedUrl = new URL(listingUrl);
          parsedUrl.hostname = `${newHostname || 'community'}.aspireiq.com`;
          listingUrl = parsedUrl.toString();

          await updateProgramCampaign({
            variables: {
              programId: campaign.program_id,
              campaign: {
                external_listing_url: listingUrl,
              }
            }
          });
        },
        CONCURRENT_CAMPAIGN_UPDATE_BATCH,
      );
    }
    setOpenUpdateModal(false);
  }, [saveClient, setOpenUpdateModal, campaigns]);

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'edit': {
        setOpenUpdateModal(true);

        break;
      }
      case 'delete': {
        if (confirm(
          `You are going to delete ${client.name} client. This is irreversible action. \nAre you sure?`,
        )) {
          deleteClientById({
            variables: {
              id: client.id,
            },
          }).then(() => history.push('/clients'));
        }

        break;
      }
      case 'completed_onboarding': {
        clientCompletedOnboarding(
          {
            variables: {
              clientId: client.id
            }
          }
        );
        break;
      }

      case 'activate': {
        if (confirm(
          `You are about to forcefully activate ${client.name} client. This will take precedence over SFDC automation for the status.\nAre you sure?`,
        )) {
          saveClient({
            variables: {
              client: {
                id: client.id,
                ...pick(client, ['name', 'hostname', 'clientGroupId']),
                forceActive: true,
              },
            },
          })
        }
        break;
      }

      case 'deactivate': {
        if (confirm(
          `You are about to forcefully deactivate ${client.name} client. This will take precedence over SFDC automation for the status.\nAre you sure?`,
        )) {
          saveClient({
            variables: {
              client: {
                id: client.id,
                ...pick(client, ['name', 'hostname', 'clientGroupId']),
                forceActive: false,
              },
            },
          })
        }
        break;
      }

      case 'reset_activeness': {
        if (confirm(
          `This action will reset manual status set for this client and SFDC automation will decide what would be a new status for the client going forward.\nAre you sure?`,
        )) {
          saveClient({
            variables: {
              client: {
                id: client.id,
                ...pick(client, ['name', 'hostname', 'clientGroupId']),
                forceActive: null,
              },
            },
          })
        }
        break;
      }
      default: {
        return;
      }
    }
  };
  const handleNavClick = ({ key }) => setSelectedRoute(key);
  const downloadBriefsForProgram = (programId: number, startDate: number, endDate: number) => {
    exportBriefs({
      variables: {
        programId,
        startDate,
        endDate
      },
    });
  }
  const ActionMenu = (
    <Menu onClick={handleActionClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="completed_onboarding"
        disabled={isNil(client) || !isNil(client.clientMetadata?.onboardingCompletedDate)}
      >
        Completed Onboarding
      </Menu.Item>
      <Menu.Divider />
      {client && client.active === true && <Menu.Item key="deactivate" className={styles.warning_light}>Deactivate</Menu.Item>}
      {client && client.active === false && <Menu.Item key="activate">Activate</Menu.Item>}
      {client && !isNil(client.forceActive) && (
        <Menu.Item key="reset_activeness">
          Reset active status&nbsp;
          <Tooltip title="Reset manual status and let SFDC automation decide"><InfoCircleOutlined /></Tooltip>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="delete" className={styles.warning}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className={cx(styles.ClientDetailView, props.className)}>
      {
        (loading || completedOnboardingLoading) &&
        <Spin size="large" />
      }
      {
        !loading && !completedOnboardingLoading && client &&
        <>
          <div className={styles.header}>
            <Title>
              {client.name}
              &nbsp;
              {client.active === true && <Tag color="success">active{!isNil(client.forceActive) && ' (forced)'}</Tag>}
              {client.active === false && <Tag color="default">inactive{!isNil(client.forceActive) && ' (forced)'}</Tag>}
              &nbsp;{client.isTestClient && <Tag color="orange">test client</Tag>}
            </Title>
            <Button
              type="default"
              loading={loggingIn}
              onClick={() => userQALogin({ variables: { clientId } })}
            >
              QA Login
            </Button>
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <Button className={styles.action}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className={styles.children}>
            <Menu
              className={styles.menu}
              onClick={handleNavClick}
              mode="horizontal"
              selectedKeys={[selectedRoute]}
            >
              <Menu.Item key="details">
                <Link to={`${match.url}/details`}>Details</Link>
              </Menu.Item>
              <Menu.Item key="users">
                <Link to={`${match.url}/users`}>Users</Link>
              </Menu.Item>
              <Menu.Item key="apps">
                <Link to={`${match.url}/apps`}>Applications</Link>
              </Menu.Item>
              <Menu.Item key="programs">
                <Link to={`${match.url}/programs`}>Programs</Link>
              </Menu.Item>
              <Menu.Item key="members">
                <Link to={`${match.url}/members`}>Members</Link>
              </Menu.Item>
              <Menu.Item key="member_tags">
                <Link to={`${match.url}/member_tags`}>Member Tags</Link>
              </Menu.Item>
              <Menu.Item key="integrations">
                <Link to={`${match.url}/integrations`}>Integrations</Link>
              </Menu.Item>
              <Menu.Item key="connected_emails">
                <Link to={`${match.url}/connected_emails`}>Connected Emails</Link>
              </Menu.Item>
              <Menu.Item key="igdm">
                <Link to={`${match.url}/igdm`}>IGDM</Link>
              </Menu.Item>
              <Menu.Item key="client_offers">
                <Link to={`${match.url}/client_offers`}>Client Offers</Link>
              </Menu.Item>
              <Menu.Item key="embedded_reporting">
                <Link to={`${match.url}/embedded_reporting`}>Embedded Reporting</Link>
              </Menu.Item>
              <Menu.Item key="applicant_review">
                <Link to={`${match.url}/applicant_review`}>Applicant Review</Link>
              </Menu.Item>
              <Menu.Item key="additional_domains">
                <Link to={`${match.url}/additional_domains`}>Additional Domains</Link>
              </Menu.Item>
            </Menu>
            <Switch>
              <Route path={`${match.path}/details`} render={
                (props) => lazyLoadComponent(ClientDetails)({ ...props, client })
              } />
              <Route path={`${match.path}/users`} render={
                (props) => lazyLoadComponent(ClientUsers)({ ...props, client })
              } />
              <Route path={`${match.path}/apps`} render={
                (props) => lazyLoadComponent(ClientApplications)({ ...props, client })
              } />
              <Route path={`${match.path}/programs`} render={
                (props) => lazyLoadComponent(ClientPrograms)({
                  ...props,
                  client,
                  downloadBriefsForProgram,
                  exportingBriefs,
                  downloadBriefsUrl
                })
              } />
              <Route path={`${match.path}/members`} render={lazyLoadComponent(ClientMembers)} />
              <Route path={`${match.path}/member_tags`} render={lazyLoadComponent(ClientMemberTags)} />
              <Route path={`${match.path}/integrations`} render={
                (props) => lazyLoadComponent(ClientIntegrations)({ ...props, client })
              } />
              <Route path={`${match.path}/connected_emails`} render={
                (props) => lazyLoadComponent(ClientConnectedEmails)({ ...props, clientId })
              } />
              <Route path={`${match.path}/igdm`} render={
                (props) => lazyLoadComponent(ClientIGDM)({ ...props, clientId })
              } />
              <Route path={`${match.path}/client_offers`} render={
                (props) => lazyLoadComponent(ClientOffers)({ ...props, clientId })
              } />
              <Route path={`${match.path}/embedded_reporting`} render={
                (props) => lazyLoadComponent(ClientEmbeddedReporting)({ ...props, clientId })
              } />
              <Route path={`${match.path}/applicant_review`} render={
                (props) => lazyLoadComponent(ApplicantReview)({ ...props, clientId })
              } />
              <Route path={`${match.path}/additional_domains`} render={
                (props) => lazyLoadComponent(AdditionalDomains)({ ...props, clientId })
              } />

              <Redirect from={`${match.path}`} to={`${match.path}/details`} />
            </Switch>
          </div>
          <EditClientDrawer
            client={client}
            open={openUpdateModal}
            onRequstClose={() => setOpenUpdateModal(false)}
            isSubmitting={saving}
            onSubmit={handleSubmit}
          />
        </>
      }
    </div>
  );
});

export default ClientDetailView;
