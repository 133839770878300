/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlloyIntegrationType {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  GORGIAS = "GORGIAS",
  KLAVIYO = "KLAVIYO",
  SLACK = "SLACK",
}

export enum ClientFeatureState {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  LIMITED = "LIMITED",
  NEW_ONLY = "NEW_ONLY",
}

export interface AffiliateOfferPromoInputType {
  desiredPromoCode: string;
  memberId: number;
  payoutId?: number | null;
}

export interface EmbeddedReportingItemInput {
  id?: number | null;
  name: string;
  position: number;
  url: string;
}

export interface InstallApplicationInput {
  applicationId: string;
}

export interface SaveApplicationInput {
  icon?: string | null;
  name: string;
}

export interface SaveClientFeatureInput {
  description: string;
  id?: number | null;
  name: string;
  state: ClientFeatureState;
}

export interface SaveClientGroupInput {
  id?: number | null;
  name?: string | null;
  organizationId: number;
}

export interface SaveClientInput {
  clientGroupId: number;
  disabledFeatureIds?: number[] | null;
  domains?: string[] | null;
  featureIds?: number[] | null;
  forceActive?: boolean | null;
  hostname?: string | null;
  id?: string | null;
  isTestClient?: boolean | null;
  memberDomainsRestricted?: boolean | null;
  name: string;
  pfaLocationIds?: string[] | null;
  pfaOrderTag?: string | null;
  salesForceId?: string | null;
}

export interface SaveOrganizationInput {
  id?: number | null;
  name: string;
}

export interface SaveUserInput {
  clientIds: string[];
  email: string;
  featureIds: number[];
  id?: string | null;
  isAdmin?: boolean | null;
  name?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
