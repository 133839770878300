import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { GET_PROGRAMS_QUERY } from '@frontend/app/queries';
import {
  GetProgramsQuery,
  GetProgramsQuery_programs,
} from '@frontend/app/queries/types/GetProgramsQuery';

export type IProgram = GetProgramsQuery_programs;
const { useEffect } = React;
type IOptions = QueryHookOptions<GetProgramsQuery>;

export function useGetPrograms(options: IOptions = {}) {
  const {
 loading, data: { programs = [] } = {}, error, refetch,
} = useQuery<GetProgramsQuery>(
    GET_PROGRAMS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    programs,
    error,
    refetch,
  };
}
