import * as React from 'react';
import cx from 'classnames';
import { toString, isEmpty, map, size, first, find } from 'lodash';

import {
  useHistory, useParams,
} from 'react-router-dom';

import { Button, Table, Typography, Popconfirm } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Title } = Typography;
import { LoadSpinner } from '@frontend/lib';

import { EditClientGroupDrawer } from './EditClientGroupDrawer';

import {
  IClientGroup,
  useGetClientGroupsForOrg,
  useSaveClientGroup,
  useDeleteClientGroupById,
} from '@frontend/app/hooks';

const { useState } = React;
import styles from './ClientGroupListView.scss';

interface IMatchParams {
  organizationId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ClientGroupListView: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const { organizationId } = useParams<IMatchParams>();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [editingClientGroup, setEditingClientGroup] = useState<IClientGroup>(null);

  const {
    loading, groups, refetch,
  } = useGetClientGroupsForOrg({
    variables: {
      id: Number(organizationId),
    },
    // disable cache
    // it's hard to update GetClientGroupsForOrgQuery
    fetchPolicy: 'no-cache',
  });

  const {
    saveClientGroup,
    loading: saving,
  } = useSaveClientGroup();
  const {
    deleteClientGroupById,
    loading: deleting,
  } = useDeleteClientGroupById();

  const goToClientsView = (groupId) => {
    history.push({
      pathname: '/clients',
      search: `group_id=${groupId}`,
    });
  };
  const goToClientDetail = (clientId: string) => {
    history.push(`/clients/${clientId}`);
  };
  const onDeleteGroupsClick = async () => {
    await Promise.all(map(selectedRowKeys, async (groupId) => await deleteClientGroupById({
      variables: {
        groupId: Number(groupId),
      },
    })));

    refetch();

    setSelectedRowKeys([]);
  };

  return (
    <div className={cx(styles.ClientGroupListView, props.className)}>
      <div className={styles.header}>
        <Title level={3}>Client Groups</Title>
        <Popconfirm
          title={`Are you sure you want to delete ${size(selectedRowKeys)} client groups?`}
          onConfirm={onDeleteGroupsClick}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className={styles.deleteButton}
            icon={<MinusOutlined />}
            type="danger"
            disabled={isEmpty(selectedRowKeys) || deleting}
            loading={deleting}
          >
            Delete({size(selectedRowKeys)})
          </Button>
        </Popconfirm>
        <Button
          className={styles.createButton}
          disabled={selectedRowKeys.length !== 1 || deleting}
          loading={deleting}
          onClick={() => {
            setEditingClientGroup(
              find(groups, { id: parseInt((selectedRowKeys[0]), 10) })
            );
            setOpenUpdateModal(true);
          }}
        >
          Edit
        </Button>
        <Button
          className={styles.createButton}
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            saveClientGroup({
              variables: {
                data: {
                  organizationId: Number(organizationId),
                },
              },
            }).then(refetch);
          }}
          loading={saving}
          disabled={saving}
        >
          Create
        </Button>
      </div>
      {
        loading &&
        <LoadSpinner />
      }
      {
        !loading && groups &&
        <Table
          rowKey={(record: IClientGroup) => toString(record.id)}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          dataSource={groups}
          className={styles.table}
        >
          <Column title="ID" key="id" render={(_, record: IClientGroup) => (
            <span
              className={styles.idCell}
              onClick={goToClientsView.bind(this, record.id)}
            >
              {record.id}
            </span>
          )} />
          <Column title="Name" key="name" render={(_, record: IClientGroup) => (
            <span
              className={styles.idCell}
            >
              {record.name}
            </span>
          )} />
          <Column title="Client" key="clients" render={(_, record: IClientGroup) => (
            <span
              className={styles.idCell}
              onClick={goToClientDetail.bind(this, first(record.clients)?.id)}
            >
              {
                // client groups are 1-1 with clients for now
                first(record.clients)?.name
              }
            </span>
          )} />
        </Table>
      }
      <EditClientGroupDrawer
        clientGroup={editingClientGroup}
        open={openUpdateModal}
        onRequestClose={() => setOpenUpdateModal(false)}
        isSubmitting={saving}
        onSubmit={(params) => {
          saveClientGroup({
            variables: {
              data: {
                ...params,
                id: editingClientGroup.id,
                organizationId: editingClientGroup.organizationId
              },
            },
          }).then(() => {
            refetch()
            setOpenUpdateModal(false)
          });
        }}
      />
    </div>
  );
});

export default ClientGroupListView;
