import gql from 'graphql-tag';

import { CLIENT_FEATURE_FRAGMENT } from './fragments';

export const GET_ALL_CLIENT_FEATURES_QUERY = gql`
  query GetAllClientFeaturesQuery {
    features: getAllClientFeatures {
      ...ClientFeatureFragment
    }
  }
  ${CLIENT_FEATURE_FRAGMENT}
`;

export const SAVE_CLIENT_FEATURE_MUTATION = gql`
  mutation SaveClientFeatureMutation($clientFeature: SaveClientFeatureInput!) {
    feature: saveClientFeature(clientFeature: $clientFeature) {
      ...ClientFeatureFragment
    }
  }
  ${CLIENT_FEATURE_FRAGMENT}
`;

export const DELETE_CLIENT_FEATURE_MUTATION = gql`
  mutation DeleteClientFeatureMutation($id: Int!) {
    deleted: deleteClientFeatureById(id: $id)
  }
`;
