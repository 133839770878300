import gql from 'graphql-tag';

export const GET_CLIENT_META_DATA = gql`
  query GetClientMetadata($clientId: String!) {
    getClientMetadata:getClientMetadata(clientId: $clientId) {
        applicantReviewFields
        allowedDomains
    }
  }
`;
