import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import { SAVE_USER_MUTATION, GET_USERS_QUERY } from '@frontend/app/queries';
import { GetUsersQuery } from '@frontend/app/queries/types/GetUsersQuery';
import {
  SaveUserMutation,
  SaveUserMutationVariables,
} from '@frontend/app/queries/types/SaveUserMutation';
import { IUser } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, user: IUser) => {
  let data: GetUsersQuery;
  try {
    data = store.readQuery<GetUsersQuery>({ query: GET_USERS_QUERY });
  } catch (err) {
    return;
  }

  store.writeQuery<GetUsersQuery>({
    query: GET_USERS_QUERY,
    data: {
      ...data,
      users: unionBy(data.users, [user], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<SaveUserMutation, SaveUserMutationVariables>;

export function useSaveUser(options: IOptions = {}) {
  const [saveUser, { loading, error }] = useMutation<
    SaveUserMutation,
    SaveUserMutationVariables
  >(SAVE_USER_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data.user);
    },
    onCompleted() {
      message.info('Successfully saved user');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveUser,
    loading,
    error,
  };
}
