import * as React from 'react';
import { map } from 'lodash';

import {
 Table, Typography,
} from '@revfluence/fresh';

import { LoadSpinner } from '@frontend/lib';
import { useGetEmailsForEmailApplicationsQuery } from '@frontend/app/hooks';
import ActionCell from './ActionCell';

const { useMemo } = React;

const { Text } = Typography;

interface TableDataType {
  email: string;
}

interface IClientConnectedEmailsProps {
  className?: string;
  clientId: string;
}

const ClientConnectedEmails: React.FC<IClientConnectedEmailsProps> = React.memo((props) => {
  const { data, loading } = useGetEmailsForEmailApplicationsQuery(
    {
      variables: {
        clientId: props.clientId,
      },
      fetchPolicy: 'no-cache',
    },
);
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: TableDataType) => <ActionCell email={record.email} />,
      width: 200,
    },
  ];
  const dataSource: TableDataType[] = useMemo(
    () => map(data?.emails || [], (email) => ({ email })), [data],
  );

  if (loading) return <LoadSpinner />;
  return (
    <div className={props.className}>
      {dataSource.length ? (
        <Table rowKey="email" columns={columns} dataSource={dataSource} pagination={false} />
      ) : (
        <Text>No connected emails</Text>
      )}
    </div>
  );
});
ClientConnectedEmails.displayName = 'ClientConnectedEmails';

export default ClientConnectedEmails;
